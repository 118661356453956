<!--
 * @Descripttion: 账号管理
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 08:51:03
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-15 10:27:08
-->
<template>
    <div class="accountManagement">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">账号管理</div>
        </div>
        <div class="case-wrap">
            <div class="title-wrap">
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
                <el-button v-if="user.kf_level == 1" type="primary" @click="isAddAccount = true">创建</el-button>
            </div>
            
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" ></el-table-column>
                    <el-table-column align="center" prop="username" label="用户名称" ></el-table-column>
                    <el-table-column align="center" prop="nickname" label="名称" ></el-table-column>
                    <el-table-column align="center" prop="kfname" label="客服名称" v-if="user.kf_level != 3"></el-table-column>
                    <el-table-column align="center" prop="kfmobile" label="客服电话" v-if="user.kf_level != 3"></el-table-column>
                    <el-table-column align="center" label="角色" prop="kf_level" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.kf_level == 1">管理员</div>
                            <div v-if="scope.row.kf_level == 2">客服</div>
                            <div v-if="scope.row.kf_level == 3">海报设计师</div>
                            <div v-if="scope.row.kf_level == 4">市场专员</div>
                            <div v-if="scope.row.kf_level == 5">全景管理员</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="email" label="邮箱" :show-overflow-tooltip="true" width="180"></el-table-column>
                    <el-table-column align="center" prop="shop_count" label="门店数" width="100" v-if="user.kf_level == 1 || user.kf_level == 2"></el-table-column>
                    <el-table-column align="center" prop="enterprise_brand_count" label="品牌数" width="100" v-if="user.kf_level == 1 || user.kf_level == 4"></el-table-column>
                    <el-table-column align="center" label="状态" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status == 1">启用</div>
                            <div v-if="scope.row.status == 2">禁用</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="last_login_ip" label="最后登录IP" ></el-table-column>
                    <el-table-column align="center" label="操作" width="120" fixed="right">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="修改密码" placement="top">
                                    <i @click="changePassword(scope.row)" class="iconfont el-icon-lock"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i v-if="user.kf_level == 1" @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i v-if="user.kf_level == 1" @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleClose" 
            :close-on-click-modal="false"
            :visible.sync="isAddAccount" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加账号</div>
            <div v-else class="title">编辑账号</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" v-if="editId<0">
                        <el-input v-model="ruleForm.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="名称" prop="nickname" required>
                        <el-input v-model="ruleForm.nickname" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="kf_level">
                        <el-select v-model="ruleForm.kf_level" placeholder="请选择角色">
                            <el-option
                                v-for="item in levelOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div v-if="ruleForm.kf_level == 2 || ruleForm.kf_level == 4">
                        <el-form-item label="客服名称" prop="kfname" >
                            <el-input v-model="ruleForm.kfname" placeholder="请输入客服名称"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="ruleForm.kf_level == 2  || ruleForm.kf_level == 4">
                        <el-form-item label="客服电话" prop="kfmobile" >
                            <el-input v-model="ruleForm.kfmobile" placeholder="请输入客服电话"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="电子邮箱" prop="email" required>
                        <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status" required>
                        <el-select v-model="ruleForm.status">
                            <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div v-if="ruleForm.kf_level == 2 || ruleForm.kf_level == 4">
                        <el-form-item label="客服二维码" prop="kfqrcode" >
                            <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="code.length<1" listType="picture-card"
                                v-model="code">
                            </aliyun-upload>
                            <div v-if="code.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="code" :src="code[0]"/>
                            </div>
                        </div>
                        </el-form-item>
                    </div>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else type="primary" @click="submitFormEdit">编辑</el-button>
                        <el-button @click="resetForm">重置</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <el-dialog title="修改账号密码"
            :before-close="handleClosePwd" 
            :close-on-click-modal="false"
            :visible.sync="isAddAccountPwd" 
            custom-class="dialog-wrap">
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="pwdForm" :rules="rulesPwd" ref="ruleFormPwd" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="密码" prop="password">
                        <el-input type="password" v-model="pwdForm.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                        <el-input type="password" v-model="pwdForm.checkPass" placeholder="请输入确认密码"></el-input>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button type="primary" @click="submitFormPwd">立即修改</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        var validatePass2 = (rule, value, callback) => {
           if (value !== this.pwdForm.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            pageMixin_index:'accountManagement',
            user: {},
            tableData: [],
            currentPage: 1,
            keyword: '',
            total: 0,
            loading: false,
            isAddAccount: false,
            isAddAccountPwd: false,
            editId: -1,
            btnLoading: false,
            addLoading: false,
            levelOptions: [{
                value: 1,
                label: '管理员'
            },{
                value: 2,
                label: '客服'
            },{
                value: 3,
                label: '海报设计师'
            },{
              value: 4,
              label: '市场专员'
            },{
              value: 5,
              label: '全景管理员'
            }],
            statusOptions: [{
                value: 1,
                label: '启用'
            },{
                value: 0,
                label: '禁用'
            }],
            code: [],
            ruleForm: {
                username: '',
                password: '',
                nickname: '',
                kf_level: '',
                kfname: '',
                kfmobile: '',
                email: '',
                kfqrcode: '',
                status: 1
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
                ],
                kf_level: [
                    { required: true, message: '请选择角色', trigger: 'change' }
                ],
                nickname: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                kfname: [
                    { required: true, message: '请输入客服名称', trigger: 'blur' },
                ],
                kfmobile: [
                    { required: true, message: '请输入客服电话', trigger: 'blur' },
                ],
                email: [
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
                ]
            },
            pwdForm: {
                password: '',
                checkPass: ''
            },
            rulesPwd:{
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
                ],
                checkPass: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur' },
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    mounted () {
        this.$route.meta.keepAlive = true
        this.user = JSON.parse(localStorage.getItem('userInfo'));
        this.getIndexList();
    },
    watch:{
        code(newVal,oldVal){
            this.ruleForm.kfqrcode = newVal[0]
        }
    },
    methods: {
        // 图片
        delImageList(){
            this.code.splice(0,1)
        },
        // 跳转到账号详情
        changeInfo(row){
            this.$router.push({ name:'storeDataInfo'});
            localStorage.setItem('userid',JSON.stringify(row))
        },
        // 获取列表数据
        getIndexList(){
            this.loading = true;
            let params = {
                keyword: this.keyword,
                page: this.currentPage
            }
            common.connect("/customerservicev1/admin/index",params,(res)=>{
                for(let i in res.data.list){
                    if(res.data.list[i].status == 0){
                        res.data.list[i].status = 2
                    }
                }
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        // 创建
        submitForm(){
            this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                this.addLoading = true
                common.connect("/customerservicev1/admin/store",this.ruleForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getIndexList();
                    this.isAddAccount = false;
                    this.addLoading = false;
                    this.$refs.ruleForm.resetFields();
                });
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            this.isAddAccount = true;
            this.addLoading = true;
            common.connect("/customerservicev1/admin/edit",{id: row.id},(res)=>{
                this.ruleForm = res.data;
                this.code[0] = res.data.kfqrcode;
                this.addLoading = false;
            });
        },
        // 编辑
        submitFormEdit(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        id: this.editId,
                        username: this.ruleForm.username,
                        nickname: this.ruleForm.nickname,
                        kf_level: this.ruleForm.kf_level,
                        kfname: this.ruleForm.kfname,
                        kfmobile: this.ruleForm.kfmobile,
                        email: this.ruleForm.email,
                        status: this.ruleForm.status,
                        kfqrcode: this.ruleForm.kfqrcode,
                    }
                    this.addLoading = true
                    common.connect("/customerservicev1/admin/update",params,(res)=>{
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.getIndexList();
                        this.isAddAccount = false;
                        this.addLoading = false;
                        this.editId = -1;
                        this.$refs.ruleForm.resetFields();
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 删除
        clickDel(row){
            this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/admin/delete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getIndexList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 确定修改密码
        submitFormPwd(){
            this.$refs.ruleFormPwd.validate((valid) => {
                if (valid) {
                    let params = {
                        id: this.editId,
                        password: this.pwdForm.password,
                        confirm_password: this.pwdForm.checkPass
                    }
                    this.addLoading = true
                    common.connect("/customerservicev1/admin/resetPassword",params,(res)=>{
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.getIndexList();
                        this.isAddAccountPwd = false;
                        this.addLoading = false;
                        this.editId = -1;
                        this.$refs.ruleFormPwd.resetFields();
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 修改密码
        changePassword(row){
            this.isAddAccountPwd = true;
            this.editId = row.id;
        },
        // 重置
        resetForm(){
            this.$refs.ruleForm.resetFields();
        },
        // 关闭添加弹窗
        handleClose(){
            this.isAddAccount = false;
            this.editId = -1;
            this.$refs.ruleForm.resetFields();
        },
        handleClosePwd(){
            this.editId = -1;
            this.isAddAccountPwd = false;
            this.$refs.ruleFormPwd.resetFields();
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getIndexList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getIndexList();
        }
    },
}
</script>

<style lang='scss'>
.accountManagement{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 20px;
                .el-input{
                    width: 200px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrap{
            .operation-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 8px;
                    cursor: pointer;
                }
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
    .upload-wrap{
        padding-top: 2px;
        .fileList-box{
            width: 150px;
            height: auto;
            position: relative;
            .delFile{
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 1px #ddd solid;
                background: rgba(100, 100, 100, 0.5);
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 99;
            }
            .pic{
                width: 100%;
            }
        }
    }
    .dialog-wrap{
        width: 650px;
        .title{
            font-size: 16px;
            color: #000;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            padding-top: 20px;
            .el-input{
                width: 500px;
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
